import React from "react";
import BookingLogo from "../../assets/img/logo-bookingcom.png";
import ExpediaLogo from "../../assets/img/logo-expedia.png";
import AirbnbLogo from "../../assets/img/logo-airbnb.png";
import ReadMore from "../../components/readMore/readMore.js";

import "./style.scss";

const RotatingIcons = () => {
  return (
    <div className="rotatingIcons">
      <div className="container">
        <div className="columns">
          <div className="column is-9">
            <h3>
              Vieni a scoprire <br />
              tutti i nostri canali
            </h3>
            <div className="section" />
            <ReadMore to="/ota" text="Scopri di più" color="green" />
          </div>
          <div className="column is-3">
            <div id="greenWave1" />
            <div id="greenWave2" />
            <div className="logoContainer">
              <img src={BookingLogo} id="floatIcon1" alt=" Logo Booking.com" />
              <img src={ExpediaLogo} id="floatIcon2" alt="Logo Expedia" />
              <img src={AirbnbLogo} id="floatIcon3" alt="Logo Airbnb" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RotatingIcons;
