import React from "react";
import Header from "../components/header/header.js";
import RotatingIcons from "../components/rotatingIcons/rotatingIcons.js";
import ChannelManagerIcon from "../assets/img/svg/channel-manager.svg";
import Arrows from "../assets/img/svg/arrows.svg";

import "../assets/styles/pages/_channel-manager.scss";

const ChannelManager = () => {
  return (
    <>
      <Header
        title="Channel Manager"
        description={`Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office.`}
        categoryIcon={{ icon: ChannelManagerIcon, alt: "Channel Manager Banner", }}
        metaDescription="Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <span className="green bold">
              Una soluzione efficace per aggiornare in modo veloce prezzi,
              listini, prenotazioni e ottimizzare le vendite. Il risultato? Mai
              più overbooking!
            </span>
            <p className="description">
              <br />
              Il software aggiorna in tempo reale, prezzi, disponibilità ed
              eventuali restrizioni delle camere in vendita nei vari canali
              online, OTA in primis. Oltre che con i portali di prenotazione
              (Booking.com, Expedia, etc.), Book@Me può dialogare anche con GDS
              (Global Distribution System), metamotori e simili.
              <br />
              <br />
              Book@Me ti offre l’utilizzo gratuito di iCal, un formato di file
              in grado di rappresentare informazioni aggregate su base
              temporale, nel nostro caso della disponibilità di tutta la
              struttura ricettiva o di una specifica tipologia di camera.
              <br />
              <br />
              Attivando iCal sarà possibile comunicare il link di esportazione
              (aggiornato in tempo reale) a tutti i portali o agenzie che
              supportano questo formato
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <RotatingIcons />
      <div className="columns twoWays">
        <div className="column is-6">
          <div className="container">
            <div className="columns">
              <div className="column is-12">          
                <p className="description">
                  <br />
                  <br />
                  <span className="green bold">Un channel manager valido dovrebbe essere connesso a due vie.</span>
                  <br />
                  <br />
                  Cosa vuol dire? Il software dovrebbe inviare e ricevere i dati
                  dai vari canali di vendita: caricando in automatico i dati
                  delle prenotazioni nelle OTA, scaricando in automatico i dati
                  delle prenotazioni dalle OTA. Proprio come Book@Me!
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12"></div>
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="infoSliderImageContainer">
            <div className="infoSliderImage">
              <div className="section" />
              <div className="parallax">
                <img src={Arrows} alt="" />
                <div id="wave" />
                <p>
                  Book@Me è interfacciato con il channel manager di RoomCloud.
                </p>
              </div>
              <div className="section" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelManager;
